import MockAdapter from 'axios-mock-adapter'
import moment from 'moment'

import {EditKpiStatus, EventType} from '../../modules/janus/enums'
import {
  Comment,
  DailyKpiInfo,
  EditKpiResponse,
  EditPssKpi,
  EditedKpi,
  EditedMonthlyKpi,
  EditedPssKpi,
  Event,
  FinalValue,
  MonthlyKpisDetails
} from '../../modules/janus/types'

import {numberRegEx, saveScenario, sleepResponse, uuidRegEx} from './utils'

import {mockStore} from '@settings/__mock__/mockStore'

const regexDate = '\\d{4}-(0[1-9]|1[0-2])(\\-\\d{2}(T\\d{2}:\\d{2}:\\d{2}(\\.\\d{3})?Z?)?)?'
const regexDateWithoutTimeZone = '\\d{4}-\\d{2}-\\d{2}'

export const enableJanusEndpoints = (mock: MockAdapter) => {
  mock
    .onGet(new RegExp(`^/janus-kpi-events/${numberRegEx}/events/${uuidRegEx}/events$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyEvents}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyEvents])
    })

  mock
    .onGet(new RegExp(`^/janus-kpi-events/${numberRegEx}/events/${regexDate}/comments$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyComments}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyComments])
    })
  mock
    .onGet(new RegExp(`^/janus-monthly-result/janus-kpi/${numberRegEx}/tree/${regexDate}$`))
    .reply(() => {
      const {
        burglengenfeld: {monthlyEntriesCategories}
      } = mockStore.scenario()

      return sleepResponse([200, monthlyEntriesCategories])
    })
  mock.onGet(new RegExp(`^/janus-pss/janus-kpi/${numberRegEx}/tree/${regexDate}$`)).reply(() => {
    const {
      burglengenfeld: {pssEntriesCategories}
    } = mockStore.scenario()

    return sleepResponse([200, pssEntriesCategories])
  })
  mock
    .onGet(
      new RegExp(
        `^/janus-monthly-result/janus-kpi/${numberRegEx}/kpi-value/${uuidRegEx}/period/${numberRegEx}/${regexDate}$`
      )
    )
    .reply(() => {
      const {
        burglengenfeld: {resourceDetails}
      } = mockStore.scenario()

      return sleepResponse([200, resourceDetails])
    })

  mock
    .onGet(
      new RegExp(
        `^/janus-pss/janus-kpi/${numberRegEx}/${regexDateWithoutTimeZone}/${uuidRegEx}/${numberRegEx}$`
      )
    )
    .reply(() => {
      const {
        burglengenfeld: {pssKpis}
      } = mockStore.scenario()

      return sleepResponse([200, pssKpis])
    })

  mock
    .onGet(new RegExp(`^/janus-daily-kpi/${numberRegEx}/daily/${regexDate}/calendar-info/1$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiMonthlyCalendar}
      } = mockStore.scenario()

      return sleepResponse([200, kpiMonthlyCalendar])
    })

  mock.onGet(new RegExp(`^/janus-daily-kpi/${numberRegEx}/cumulated/${regexDate}`)).reply(() => {
    const {
      burglengenfeld: {kpiCumulatedDailyEntries}
    } = mockStore.scenario()

    return sleepResponse([200, kpiCumulatedDailyEntries])
  })

  mock
    .onGet(new RegExp(`^/janus-daily-kpi/${numberRegEx}/daily/${regexDate}/calendar-info/2$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiWeeklyCalendar}
      } = mockStore.scenario()

      return sleepResponse([200, kpiWeeklyCalendar])
    })

  mock
    .onGet(
      new RegExp(
        `/asset-config/janus-production-data/plant/${uuidRegEx}/upm-type/Department?typeUpmId=${uuidRegEx}&`
      )
    )
    .reply(() => {
      const {
        burglengenfeld: {departmentConfig}
      } = mockStore.scenario()

      return sleepResponse([200, departmentConfig])
    })

  mock
    .onPost(new RegExp(`^/janus-kpi-events/${numberRegEx}/events/${uuidRegEx}/comments$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {value} = JSON.parse(config.data as string) as Comment

      const newComment: Comment = {
        commentDate: moment.utc().toISOString(),
        id: Date.now().toString(),
        kpiName: 'kpi name for new comment',
        kpiId: '1',
        userName: 'User Name',
        value,
        commentParentId: null
      }
      const newEvent: Event = {
        eventDate: moment.utc().toISOString(),
        eventType: EventType.Comment,
        id: Date.now().toString(),
        kpiName: 'kpi name for new comment',
        version: '1',
        userName: 'User Name',
        value
      }

      scenario.burglengenfeld.kpiDailyComments.janusComments = [
        ...scenario.burglengenfeld.kpiDailyComments.janusComments,
        newComment
      ]
      scenario.burglengenfeld.kpiDailyEvents.events = [
        ...scenario.burglengenfeld.kpiDailyEvents.events,
        newEvent
      ]

      saveScenario(scenario)
      return sleepResponse([201, newComment])
    })

  mock
    .onPost(
      new RegExp(`^/janus-monthly-result/janus-kpi/${numberRegEx}/kpi/${uuidRegEx}/${regexDate}$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const newDailyKpis = JSON.parse(config.data as string) as MonthlyKpisDetails[]

      if (scenario.burglengenfeld.resourceDetails?.[0]?.kpis?.[0]?.values) {
        const valuesLength = scenario.burglengenfeld.resourceDetails[0].kpis[0].values?.length || 0

        scenario.burglengenfeld.resourceDetails[0].kpis[0].values[valuesLength - 1].value =
          newDailyKpis[0][0].value

        saveScenario(scenario)
      }
      return sleepResponse([201, 1])
    })

  mock
    .onPost(new RegExp(`^/janus-daily-kpi/${numberRegEx}/daily/${regexDate}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const newDailyKpis = JSON.parse(config.data as string) as DailyKpiInfo[]

      scenario.burglengenfeld.kpiDailyEntries = newDailyKpis

      const janusDailyKpis: EditedKpi[] = newDailyKpis.map((entry) => {
        if (entry.value?.includes('e')) {
          return {id: entry.id, status: EditKpiStatus.ValidationFail}
        }
        return {id: entry.id, status: EditKpiStatus.Success}
      })

      const response: EditKpiResponse = {janusDailyKpis}

      saveScenario(scenario)
      return sleepResponse([201, response])
    })

  mock
    .onPost(
      new RegExp(
        '/janus-pss/janus-kpi/0014/2025-04-01T00:00:00/3fa85f64-5717-4562-b3fc-2c963f66afa8'
      )
    )
    .reply(() => {
      const kpis: EditedPssKpi[] = [
        {
          id: '1',
          status: EditKpiStatus.Success,
          specificKpiId: '1',
          groupId: '1'
        }
      ]

      const response: EditedPssKpi[] = kpis

      return sleepResponse([201, response])
    })

  mock
    .onPost(new RegExp(`^/janus-pss/janus-kpi/${numberRegEx}/${regexDate}/${uuidRegEx}`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const requestKpis = JSON.parse(config.data as string) as EditPssKpi[]

      const newPssKpis = scenario.burglengenfeld.pssKpis.kpiGroups[0].kpis

      if (newPssKpis?.[0]?.values?.[0]) {
        newPssKpis[0].values[1].value = requestKpis[1].value
      }

      const kpis: EditedMonthlyKpi[] = newPssKpis.map((entry) => {
        return {
          id: entry.specificKpiId,
          status: EditKpiStatus.Success,
          specificKpiId: entry.specificKpiId
        }
      })
      scenario.burglengenfeld.pssKpis.kpiGroups[0].kpis = newPssKpis

      const response: EditedMonthlyKpi[] = kpis

      saveScenario(scenario)
      return sleepResponse([201, response])
    })

  mock
    .onPatch(new RegExp(`/janus-daily-kpi/${numberRegEx}/daily/${regexDate}/approve`))
    .reply(() => {
      const scenario = mockStore.scenario()
      const newCalendarInfo = {...scenario.burglengenfeld.kpiMonthlyCalendar}

      newCalendarInfo.days = scenario.burglengenfeld.kpiMonthlyCalendar.days.map((day) => {
        return {...day, status: 5}
      })

      scenario.burglengenfeld.kpiMonthlyCalendar = newCalendarInfo
      saveScenario(scenario)

      return sleepResponse([201, 1])
    })

  mock.onPatch(new RegExp(`/janus-pss/janus-kpi/${numberRegEx}/${regexDate}/approve`)).reply(() => {
    return sleepResponse([201, 1])
  })

  mock
    .onPost(new RegExp(`^/janus-daily-kpi/{numberRegEx}/cumulated/${regexDate}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const newDailyKpis = JSON.parse(config.data as string) as FinalValue[]

      scenario.burglengenfeld.kpiCumulatedDailyEntries.finalValues = newDailyKpis

      saveScenario(scenario)
      return sleepResponse([201, {}])
    })

  mock.onGet(new RegExp(`^/janus-daily-kpi/${numberRegEx}/daily/2024-07-20T00:00:00`)).reply(() => {
    const {
      burglengenfeld: {kpiDailyEntriesSaved}
    } = mockStore.scenario()

    return sleepResponse([200, kpiDailyEntriesSaved])
  })

  mock
    .onGet(new RegExp(`^/janus-daily-kpi/${numberRegEx}/daily/${regexDate}(\\?.*)?$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiDailyEntries}
      } = mockStore.scenario()

      return sleepResponse([200, kpiDailyEntries])
    })

  return mock
}
